import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import calendar from "dayjs/plugin/calendar";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import Calendar2 from "react-calendar";
import { Checkbox } from "./checkbox";
import { Label } from "./label";
import { Textarea } from "./textarea";
import { ReactElement, useState } from "react";
import { Button } from "./button";
import { CalendarIcon, Check, ChevronsUpDown, Eye, EyeOff } from "lucide-react";
import { format } from "date-fns";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Calendar } from "./calendar";
import { cn } from "@/lib/utils";
import dayjs from "dayjs";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import ReactTimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import MultiSelect, { MultiValue } from "react-select";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { ScrollArea as ScrollArea2 } from "./scroll-area";
import { CheckedState } from "@radix-ui/react-checkbox";
dayjs.extend(calendar);

export const RequiredStar = ({ required }: { required: boolean }) =>
  required ? (
    <span className="ml-1 text-red-500 text-lg h-[15px] inline-block align-sub">
      *
    </span>
  ) : null;

export const TextInput = ({
  form,
  label,
  inputClass,
  placeholder,
  name,
  type = "text",
  required = false,
  disabled = false,
  min = 0,
  max = undefined,
  defaultValue = undefined,
  autoComplete = "on",
}: {
  form: any;
  name: string;
  label: string;
  placeholder: string;
  inputClass?: string;
  required?: boolean;
  disabled?: boolean;
  type?: "text" | "email" | "password" | "number" | "tel";
  min?: number;
  max?: number;
  autoComplete?: "on" | "off" | string;
  defaultValue?: number | string | undefined;
}) => {
  // min = type === "number" ? 0 : undefined;
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem>
          <FormLabel>
            {label} <RequiredStar required={required} />
          </FormLabel>
          <FormControl>
            <Input
              autoComplete={autoComplete}
              disabled={disabled}
              defaultValue={defaultValue}
              type={type}
              max={max}
              required={required}
              className={`input ${inputClass}
              ${fieldState.error?.message ? "border-red-500" : "!border-border"}
              `}
              placeholder={placeholder}
              min={min}
              {...field}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const CustomSelect = ({
  form,
  label,
  placeholder,
  name,
  items,
  required = false,
  disabled = false,
  className,
  noEmptyVal,
  onChange,
}: {
  form: any;
  name: string;
  label?: string;
  placeholder?: string;
  items: { value: string; label: string }[];
  required?: boolean;
  disabled?: boolean;
  className?: string;
  noEmptyVal?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: string) => void;
}) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel>
            {label} <RequiredStar required={required} />
          </FormLabel>
          <Select
            onValueChange={onChange ?? field.onChange}
            defaultValue={field.value}
            disabled={disabled}
            value={field.value}
          >
            <FormControl className={`bg-background ${className}`}>
              <SelectTrigger>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent className="pb-1">
              <ScrollArea className="h-full max-h-52">
                <SelectGroup>
                  {!noEmptyVal ? (
                    <SelectItem value={""}>{placeholder}</SelectItem>
                  ) : null}
                  {items?.map(({ value, label }, i) => (
                    <SelectItem value={value} key={i}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </ScrollArea>
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const CustomSelectOnChange = ({
  form,
  label,
  placeholder,
  name,
  items,
  required = false,
  disabled = false,
  className,
  noEmptyVal,
  onChange,
}: {
  form: any;
  name: string;
  label?: string;
  placeholder: string;
  items: { value: string; label: string }[];
  required?: boolean;
  disabled?: boolean;
  className?: string;
  noEmptyVal?: boolean;
  onChange: any;
}) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel>
            {label} <RequiredStar required={required} />
          </FormLabel>
          <Select
            onValueChange={onChange}
            defaultValue={field.value}
            disabled={disabled}
            value={field.value}
          >
            <FormControl className={`bg-background ${className}`}>
              <SelectTrigger>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent className="pb-1">
              <ScrollArea className="h-full max-h-52">
                <SelectGroup>
                  {!noEmptyVal ? (
                    <SelectItem value={""}>{placeholder}</SelectItem>
                  ) : null}
                  {items?.map(({ value, label }, i) => (
                    <SelectItem value={value} key={i}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </ScrollArea>
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const CustomCheckbox = ({
  form,
  desc,
  label,
  name,
  disabled = false,
  onChange = () => {},
}: {
  form: any;
  desc?: string;
  label: string;
  name: string;
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange?: (checked: CheckedState) => void;
}) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md ">
          <FormControl>
            <Checkbox
              disabled={disabled}
              checked={field.value}
              onCheckedChange={(e) => {
                field.onChange(e);
                onChange(e);
              }}
            />
          </FormControl>
          <div className="space-y-1 leading-none">
            <FormLabel>{label}</FormLabel>
            {desc ? <FormDescription>{desc}</FormDescription> : ""}
          </div>
        </FormItem>
      )}
    />
  );
};

export const FileInput = ({
  value,
  onChange,
  label,
  id,
  className,
  disabled = false,
}: {
  value: any;
  onChange: any;
  label?: string;
  id: string;
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <div className={`grid w-full max-w-xs items-center gap-1.5 ${className}`}>
      {label ? <Label htmlFor={id}>{label}</Label> : ""}
      <Input
        disabled={disabled}
        value={value}
        onChange={onChange}
        id={id}
        type="file"
      />
    </div>
  );
};

export const CustomTextArea = ({
  form,
  label,
  className,
  placeholder,
  name,
  required = false,
  disabled = false,
}: {
  form: any;
  name: string;
  label: string;
  placeholder: string;
  className?: string;
  required?: boolean;
  disabled?: boolean;
}) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>
            {label} <RequiredStar required={required} />
          </FormLabel>
          <FormControl>
            <Textarea
              required={required}
              placeholder={placeholder}
              className={`resize-none bg-background ${className}`}
              {...field}
              disabled={disabled}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const PasswordInput = ({
  form,
  label = "Password",
  required = false,
  disabled = false,
  name = "password",
  autoComplete = "on",
}: {
  form: any;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  name?: string;
  autoComplete?: "on" | "off" | string;
}) => {
  const [show, setShow] = useState(false);
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="relative">
          <FormLabel>
            {label} <RequiredStar required={required} />
          </FormLabel>
          <FormControl>
            <Input
              disabled={disabled}
              required={required}
              type={show ? "text" : "password"}
              placeholder="Password"
              {...field}
              autoComplete={autoComplete}
            />
          </FormControl>
          <Button
            className="absolute p-0 w-10 grid items-center rounded-full aspect-square right-0 top-[26px] !bg-transparent"
            onClick={() => setShow(!show)}
            variant={"ghost"}
            type="button"
          >
            {show ? <Eye size={18} /> : <EyeOff size={18} />}
          </Button>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const DatePicker = ({
  form,
  label,
  name,
  previousDateDisabled = true,
}: {
  form: any;
  label: string;
  name: string;
  previousDateDisabled?: boolean;
}) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel>{label}</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant={"outline"}
                  className={cn(
                    "w-full pl-3 h-11 text-left font-normal",
                    !field.value && "text-muted-foreground"
                  )}
                >
                  {field.value ? (
                    format(field.value, "PPP")
                  ) : (
                    <span>Pick a date</span>
                  )}
                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={field.value}
                onSelect={field.onChange}
                disabled={(date) => previousDateDisabled && date < new Date()}
                initialFocus
              />
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export const FormMultiSelect = ({
  form,
  placeholder = "Select",
  label,
  name,
  onInputChange = () => {},
  disabled = false,
  required = false,
  onSelectEffect = () => {},
  isLoading = false,
  options,
}: {
  name: string;
  form: any;
  placeholder: string;
  label: string;
  required?: boolean;
  inputPlaceholder?: string;
  // eslint-disable-next-line no-unused-vars
  onInputChange?: (value: string) => void;
  disabled?: boolean;
  onSelectEffect?: (
    // eslint-disable-next-line no-unused-vars
    option: MultiValue<{
      value: string;
      label: string;
    }>
  ) => void;
  isLoading?: boolean;
  options: { value: string; label: string }[];
}) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <FormItem className="flex flex-col">
            <FormLabel>{label}</FormLabel>
            <FormLabel>
              {label} <RequiredStar required={required} />
            </FormLabel>
            <span>{label}</span>
            <MultiSelect
              // styles={{
              //   option(base, props) {
              //     return {
              //       ...base,
              //       backgroundColor: props.isFocused ? "red" : "blue"
              //     };
              //   },
              // }}
              isDisabled={disabled}
              value={field.value}
              closeMenuOnSelect={false}
              isMulti
              isLoading={isLoading}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={placeholder}
              onChange={(option) => {
                field.onChange(option);
                onSelectEffect(option);
              }}
              onInputChange={(value) => onInputChange(value)}
              classNames={{
                control: () => {
                  return `!bg-background  ${
                    fieldState.error?.message
                      ? "border-red-500"
                      : "!border-border"
                  } !min-h-[44px]`;
                },
                multiValue: () => "!bg-secondary !text-primary",
                multiValueLabel: () => "!text-primary",
                menuList: () => "!bg-primary-foreground",
                option: (props) =>
                  props.isFocused ? "!bg-secondary" : "!bg-primary-foreground",
                input: () => "!text-primary",
              }}
              options={options}
            />
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};

export const DateRangePicker = ({
  form,
  label,
  name,
}: {
  form: any;
  label: string;
  name: string;
}) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => {
        return (
          <FormItem className="flex flex-col">
            <FormLabel>{label}</FormLabel>
            <Popover>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    variant={"outline"}
                    className={cn(
                      "w-full pl-3 h-11 text-left font-normal",
                      !field.value && "text-muted-foreground"
                    )}
                  >
                    {field.value.from ? (
                      field.value.to ? (
                        <>
                          {format(field.value.from, "LLL dd, y")} -{" "}
                          {format(field.value.to, "LLL dd, y")}
                        </>
                      ) : (
                        format(field.value.from, "LLL dd, y")
                      )
                    ) : (
                      <span>Pick a date range</span>
                    )}
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="range"
                  selected={field.value}
                  onSelect={field.onChange}
                  disabled={(date) => {
                    return date < new Date();
                  }}
                  initialFocus
                  numberOfMonths={2}
                />
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};

export const CardFormItems = ({
  title,
  description,
  content,
  footer,
  className = "w-full",
  titleClass = "",
  headerClass = "",
}: {
  title: string;
  className?: string;
  description?: string;
  footer?: ReactElement;
  content: ReactElement;
  titleClass?: string;
  headerClass?: string;
}) => {
  return (
    <Card className={cn(className, "border-main")}>
      <CardHeader className={cn(headerClass, "bg-main py-4")}>
        <CardTitle className={cn(titleClass, "text-xl")}>{title}</CardTitle>
        {description ? <CardDescription>{description}</CardDescription> : null}
      </CardHeader>
      <CardContent className="pt-4">{content}</CardContent>
      {footer ? (
        <CardFooter className="flex justify-between">{footer}</CardFooter>
      ) : null}
    </Card>
  );
};

export const DatePicker2 = ({
  form,
  label,
  name,
  className,
  required = false,
}: {
  form: any;
  required?: boolean;
  label: string;
  name: string;
  className?: string;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => {
        return (
          <FormItem className="flex flex-col relative">
            <FormLabel>
              {label} <RequiredStar required={required} />
            </FormLabel>
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    variant={"outline"}
                    className={cn(
                      "w-full min-w-[150px] pl-3 h-11 text-left font-normal",
                      !field.value && " text-muted-foreground ",
                      className
                    )}
                  >
                    {field.value ? (
                      dayjs(field.value).format("DD MMM YYYY")
                    ) : (
                      <span>Pick a date</span>
                    )}
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-full p-0" align="start">
                <Calendar2
                  value={field.value ? new Date(field.value) : new Date()}
                  onChange={(e: any) => field.onChange(dayjs(e)?.toISOString())}
                  onClickDay={() => setOpen(false)}
                />
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};

export const TimePicker = ({
  form,
  label,
  name,
}: {
  form: any;
  label: string;
  name: string;
}) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => {
        return (
          <FormItem className="flex flex-col relative">
            <FormLabel>{label}</FormLabel>
            <ReactTimePicker
              id="clock"
              clearIcon={false}
              disableClock
              value={field.value}
              hourPlaceholder="--"
              minutePlaceholder="--"
              onChange={field.onChange}
            />
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};

export const CustomComboBox = ({
  form,
  placeholder = "Select",
  label,
  arr,
  name,
  onInputValueChange = () => {},
  inputPlaceholder = "Search",
  disabled = false,
  required = false,
  onSelect = () => {},
  onSelectEffect = () => {},
}: {
  name: string;
  form: any;
  placeholder: string;
  label: string;
  required?: boolean;
  inputPlaceholder?: string;
  arr: { value: string; label: string }[] | [];
  onInputValueChange?: any;
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars
  onSelect?: (value: any) => void;
  // eslint-disable-next-line no-unused-vars
  onSelectEffect?: (value: any) => void;
}) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <FormItem className="flex flex-col">
            <FormLabel>
              {label}
              <RequiredStar required={required} />
            </FormLabel>
            <Popover>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    variant="outline"
                    disabled={disabled}
                    role="combobox"
                    className={cn(
                      `w-full flex py-3 justify-between h-[44px]  ${
                        fieldState.error?.message
                          ? "border-red-500"
                          : "!border-border"
                      }

                      `,
                      !field.value && "text-muted-foreground"
                    )}
                  >
                    {field.value
                      ? arr.find((item) => item.value === field.value)?.label
                      : placeholder}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="p-0 w-full max-w-5xl">
                <Command>
                  <CommandInput
                    placeholder={inputPlaceholder}
                    onValueChange={onInputValueChange}
                  />

                  <CommandGroup>
                    {arr.length !== 0 ? (
                      <ScrollArea2
                        className={arr.length > 6 ? "h-52" : "h-full"}
                      >
                        {arr.map((item) => (
                          <CommandItem
                            value={item.label}
                            key={item.value}
                            onSelect={() => {
                              form.setValue(name, item.value);
                              onSelect(item.value);
                              onSelectEffect(item.value);
                            }}
                          >
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                item.value === field.value
                                  ? "opacity-100"
                                  : "opacity-0"
                              )}
                            />
                            {item.label}
                          </CommandItem>
                        ))}
                      </ScrollArea2>
                    ) : (
                      <p className="min-h-[50px] text-center pt-4">
                        No Items found.
                      </p>
                    )}
                  </CommandGroup>
                </Command>
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};
